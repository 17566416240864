.collectables-container{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.collectable{
  width: 54px;
  height: 54px;
  background-position: left center;
  display: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-size: auto 54px;
}

.collectable0{
  background-position: 0;
}

.collectable1{
  background-position: -54px;
}

.collectable2{
  background-position: -108px;
}

.collectable3{
  background-position: -162px;
}

.picked{
  opacity: 0;
}

@keyframes collectableGolden1Anim {
  100% {
    background-position: -756px;
  }
}

.collectableGolden1{
  width: 54px;
  height: 54px;
  background-position: left center;
  display: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-size: auto 54px;
  animation: collectableGolden1Anim 1s steps(14) infinite;

}

@keyframes collectableGolden2Anim {
  100% {
    background-position: -864px;
  }
}

.collectableGolden2{
  width: 54px;
  height: 54px;
  background-position: left center;
  display: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-size: auto 54px;
  animation: collectableGolden2Anim 1s steps(16) infinite;

}

@keyframes collectableGolden3Anim {
  100% {
    background-position: -810px;
  }
}

.collectableGolden3{
  width: 54px;
  height: 54px;
  background-position: left center;
  display: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-size: auto 54px;
  animation: collectableGolden3Anim 1s steps(15) infinite;

}