.overlayWrapper{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.scrim{
  background-color: rgba(41, 84, 162, 0.5);
}
.blackScrim{
  background-color: rgba(0, 0, 0, 0.8);
}

.overlayInitialWrapper{
  padding: 138px 44px 68px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayInitialAnimationWrapper{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 44px 68px;
}

@keyframes initialControlsMobile {
  100% {
    background-position: -384px;
  }
}

.overlayInitialControlsMobile{
  width: 96px;
  height: 50px;
  background-position: left center;
  background-size: auto 50px;
  animation: initialControlsMobile 1s steps(4) infinite;
}

@keyframes initialControlsDesktop {
  100% {
    background-position: -384px;
  }
}

.overlayInitialControlsDesktop{
  width: 96px;
  height: 48px;
  background-position: left center;
  background-size: auto 48px;
  animation: initialControlsDesktop 1s steps(4) infinite;
}

/* Lose overlay */

.overlayLoseWrapper{
  padding: 138px 44px 68px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlayLoseCtaWrapper{
  margin-bottom: -60px;
}

@keyframes overlayLoseTextAnim {
  100% {
    background-position: -488px;
  }
}

.overlayLoseText{
  width: 244px;
  height: 22px;
  background-position: left center;
  background-size: auto 22px;
  animation: overlayLoseTextAnim 3s steps(2) infinite;
}

@keyframes overlayLoseCtaAnim {
  100% {
    background-position: -536px;
  }
}

.overlayLoseCta{
  margin-top: 16px;
  width: 268px;
  height: 44px;
  background-position: left center;
  background-size: auto 44px;
  animation: overlayLoseCtaAnim 1s steps(2) infinite;
}

.overlayInfoWrapper{
  padding: 138px 44px 68px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  color: #fff;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
}

.textCaption{
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 8px;
}

.textBlock{
  margin-bottom: 28px;
}

.textBlock:last-of-type{
  margin-bottom: 0;
}

.textBlock span{
  display: block;
}
