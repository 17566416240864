.clouds-wrapper{
  position: absolute;
  top: 116px;
  right: 0;
  left: 0;
  z-index: 0;
  height: 96px;
}

.clouds{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 96px;
}

.background-dengue{
  z-index: 1;
  opacity: 0;
  transition: opacity 2s linear;
}

.background-dengue-appear{
  opacity: 1;
}

.pointsWrapper{
  position: absolute;
  top: 116px;
  right: 0;
  left: 0;
  z-index: 0;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #AE0927;
  text-shadow: 0px 2px 0px #3F673F;
}

@keyframes pointsAnimationGolden {
  0% {
    color: #AE0927;
    text-shadow: 0px 2px 0px #3F673F;
  }

  25% {
    color: #FFF200;
    text-shadow: 0px 2px 0px #F9BD00;
  }

  50% {
    color: #FFF200;
    text-shadow: 0px 2px 0px #F9BD00;
  }

  100% {
    color: #AE0927;
    text-shadow: 0px 2px 0px #3F673F;
  }
}

.pointsWrapperGolden{
  animation: pointsAnimationGolden 0.4s;
}

.pointsWrapperOnTop{
  z-index: 20000;
}

.mountains-wrapper{
  position: absolute;
  right: 0;
  bottom: 112px;
  left: 0;
  z-index: 0;
  height: 120px;
}

.mountains{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.stars{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@keyframes pointsAnimation {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.pointsAnimation{
  animation: pointsAnimation 0.2s;
}

@media (max-width: 640px) {
  .clouds-wrapper{
    position: fixed;
  }

  .clouds{
    position: fixed;
    top: 116px;
  }

  .pointsWrapper{
    position: fixed;
  }

  .mountains-wrapper{
    position: fixed;
  }

  .mountains{
    position: absolute;
    bottom: 0;
    height: 120px;
  }

  .stars{
    position: fixed;
  }
}