.container{
  position: relative;
  height: 100%;
  width: 100%;
  padding: 116px 22px 112px;
  max-width: 1200px;
  margin: 0 auto;
}

.backgroundOverlayWrapper{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.backgroundOverlay{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, #3327AA 0%, #29A7E0 100%);
  opacity: 1;
  transition: opacity 2s linear;
}

.backgroundOverlayDengue{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, #332677 0%, #3326aa 100%);
}
.backgroundOverlayHide{
  opacity: 0;
}