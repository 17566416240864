.playerDesktop{
  width: 132px;
  height: 100px;
  background-position: left center;
  background-size: auto 100px;
  position: fixed;
  bottom: 112px;
}

@keyframes desktopIdle {
  100% {
    background-position: -264px;
  }
}

.playerDesktopIdle{
  animation: desktopIdle 1s steps(2) infinite;
}

.playerMobile{
  width: 60px;
  height: 72px;
  background-position: left center;
  background-size: auto 72px;
  position: fixed;
  bottom: 112px;
}

@keyframes mobileIdle {
  100% {
    background-position: -120px;
  }
}

.playerMobileIdle{
  animation: mobileIdle 1s steps(2) infinite;
}
