@font-face {
  font-family: "visitor1";
  src: local("visitor1"), url(./fonts/visitor1.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.app{
  width: 100vw;
  min-height: 100%;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  position: relative;
  overflow: hidden;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: "visitor1", 'Courier New', Courier, monospace;
  font-size: 48px;
   ;
}

*:focus {
  outline: none !important;
}

.overlay{
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 999;
}

.canvas-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}