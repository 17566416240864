.logoWrapper{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10002;
  height: 116px;
}

.bordersCta{
  width: 32px;
  height: 32px;
  margin-bottom: -12px;
}

.marginLeft{
  margin-left: 22px;
}
.marginRight{
  margin-right: 22px;
}

.border{
  position: absolute;
}

.cornerTopLeft{
  top: 0;
  left: 0;
  z-index: 10001;
}

.cornerTopRight{
  top: 0;
  right: 0;
  z-index: 10001;
}

.cornerBottomLeft{
  bottom: 0;
  left: 0;
  z-index: 10001;
}

.cornerBottomRight{
  bottom: 0;
  right: 0;
  z-index: 10001;
}

.borderTop{
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.borderBottom{
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.borderLeft{
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.borderRight{
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
}