.floor-wrapper{
  position: absolute;
  right: 0;
  bottom: 22px;
  left: 0;
  z-index: 0;  
  height: 90px;
}

.floor{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.floor-dengue{
  z-index: 1;
  opacity: 0;
  transition: opacity 2s linear;
}

.floor-dengue-appear{
  opacity: 1;
}

@media (max-width: 640px) {
  .floor-wrapper{
    position: fixed;
    bottom: 22px;
  }

  .floor{
    bottom: 0;
  }
}