@keyframes splashSpawn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes logoSpawn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.splash{
  padding: 138px 44px 68px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animateSplash{
  pointer-events: none;
  animation: splashSpawn 0.75s linear 0.1s both;
}

.bkmLogo{
  animation: logoSpawn 3s linear 0.5s both;
}